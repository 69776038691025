<template>
  <div>
    <c-search-box @enter="getEquipClassList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="hazardMachineItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="hazardMachineFlag"
            label="유해위험기계기구 여부"
            v-model="searchParam.hazardMachineFlag"
          ></c-select>
        </div> -->
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-tree-table
          title="설비유형 목록"
          parentProperty="upEquipmentTypeCd"
          customID="equipmentTypeCd"
          :columns="gridClass.columns"
          :data="gridClass.data"
          :columnSetting="false"
          :gridHeight="gridClass.height"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getEquipClassList" />
            </q-btn-group>
          </template>
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-table
          ref="itemTable"
          title="설비유형별 예방점검항목 목록"
          :columns="insItemgrid.columns"
          :data="insItemgrid.data"
          :merge="insItemgrid.merge"
          selection="multiple"
          :usePaging="false"
          :expandAll="true"
          :gridHeight="insItemgrid.height"
          rowKey="rowkey"
        >
          <template slot="table-chip">
            <q-btn-group outline >
              <q-chip>
                  <q-avatar icon="push_pin" color="green" text-color="white" /> 
                  {{ "설비유형 : " + rowTypeName}}
              </q-chip>
            </q-btn-group>
          </template>
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && updateMode" label="추가" icon="add" :showLoading="false" @btnClicked="addrow" />
              <c-btn v-if="editable && updateMode && insItemgrid.data.length > 0" :showLoading="false" label="삭제" icon="remove" @btnClicked="remove"/>
              <c-btn label="저장" icon="save" @btnClicked="saveClass" v-if="editable && updateMode && insItemgrid.data.length > 0" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'checkItemNm'">
              <c-text-column
                :editable="editable"
                :col="col"
                :props="props"
                @datachange="datachange(props, col)"
              >
              </c-text-column>
              <span>
                <div>
                  <q-btn-group outline class="ColumInnerBtnGroup">
                    <template v-for="(btn, idx) in col.btns">
                      <q-btn
                        :key="idx"
                        v-if="editable"
                        :label="btn.label"
                        :icon="btn.icon ? btn.icon : void 0"
                        :color="btn.color ? btn.color : 'blue-grey-4'"
                        :text-color="btn.textColor ? btn.textColor : 'white'"
                        class="ColumInnerBtn"
                        align="center"
                        @click.stop="innerBtnClicked(col, props, btn)">
                        <q-tooltip v-if="btn.tooltip">
                          <span v-html="btn.tooltip" />
                        </q-tooltip>
                      </q-btn>
                    </template>
                  </q-btn-group>
                </div>
              </span>
            </template>
          </template>
        </c-table> 
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default 
  {
  name: 'equipment-class-ins-item',
  data() {
    return {
      gridClass: {
        columns: [
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형',
            align: 'left',
            sortable: false,
          },
          {
            name: 'hazardMachineFlagNm',
            field: 'hazardMachineFlagNm',
            label: '유해위험기계기구<br>여부',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          // {
          //   name: 'useFlagNm',
          //   field: 'useFlagNm',
          //   label: '사용여부',
          //   align: 'center',
          //   style: 'width:80px',
          //   sortable: false,
          // },
        ],
        data: [],
        height: '720px'
      },
      insItemgrid: {
        columns: [],
        data: [],
        height: '720px'
      },
      searchParam: {
        plantCd: null,
        hazardMachineFlag: null,
        useFlag: 'Y',
      },
      equipClassInsItemData: {
        equipmentType: '',
        plantCd: '',
        equipmentCheckTypeCd: null,
        checkItemNo: '',
        checkItemNm: '',
        checkMethodCd: null,
        decisionBasis: '',
        checkCaution: '',
      },
      editable: true,
      listUrl: '',
      itemDetailUrl: '',
      deleteUrl: '',
      insertUrl: '',
      updateUrl: '',
      checkUrl: '',
      infoInsertUrl: '',
      infoUpdateUrl: '',
      infoDeleteUrl: '',
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      hazardMachineItems: [
        { code: 'Y', codeName: '유해위험기계기구' },
        { code: 'N', codeName: '일반' },
      ],
      checkMethodItems: [],
      isSave: false,
      isSave2: false,
      isDelete: false,
      updateMode: false,
      updateMode2: false,
      saveType: 'POST',
      removeMode: false,
      key: {
        equipmentTypeCd: '',
        plantCd: '',
        equipmentCheckTypeCd: null,
        checkItemNo: '',
        checkItemNm: '',
      },
      rowTypeName: '',
      rowCheckItemName: '',
      tempInsGridDetail: {
        equipmentTypeCd:  '',
        plantCd:  '',
        equipmentCheckTypeCd:  '',
        checkItemNo:  '',
        checkItemNm:  '',
        checkMethodCd: null,
        decisionBasis: '',
        checkCaution: '',
      },
    };
  },
  watch: {

  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.equipment.class.list.url;
      this.itemDetailUrl = selectConfig.mdm.equipment.class.insItem.list.url;
      this.insertUrl = transactionConfig.mdm.equipment.class.insItem.insert.url;
      this.deleteUrl = transactionConfig.mdm.equipment.class.insItem.delete.url;
      this.getEquipClassList();

      this.$comm.getComboItems('MDM_METHOD_CHECK_CD').then(_result => {
        this.$comm.getComboItems('MDM_CHECK_KIND_CD').then(_result2 => {
          this.insItemgrid.columns = [
            {
              name: 'equipmentCheckTypeCd',
              field: 'equipmentCheckTypeCd',
              label: '점검종류',
              required: true,
              align: 'center',
              style: 'width:100px',
              type: 'select',
              comboItems: _result2,
              sortable: false,
            },
            {
              name: 'checkItemNm',
              field: 'checkItemNm',
              label: '점검항목 내용',
              btns: [
                { label: '', icon: 'add', color: 'orange', tooltip: '점검항목별 검사방법 추가' },
              ],
              type: 'custom',
              align: 'left',
              sortable: false,
            },
            {
              name: 'checkMethodCd',
              field: 'checkMethodCd',
              required: true,
              label: '검사방법',
              align: 'center',
              style: 'width:150px',
              type: 'select',
              comboItems: _result,
              sortable: false,
            },
            {
              name: 'decisionBasis',
              field: 'decisionBasis',
              label: '판정기준',
              align: 'left',
              type: 'text',
              style: 'width:250px',
              sortable: false,
            },
            {
              name: 'checkCaution',
              field: 'checkCaution',
              label: '점검시 안전사항',
              align: 'left',
              type: 'text',
              style: 'width:250px',
              sortable: false,
            },
          ]
          this.insItemgrid.merge =  [
            { index: 0, colName: "checkItemNo" },
            { index: 1, colName: "checkItemNo" },
          ]
        });
      });
    },
    getEquipClassList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridClass.data = _result.data;
        this.updateMode = false;
        this.updateMode2 = false;
      },);
    },
    rowClick(row) {
      if (row) {
        this.rowTypeName = this.$_.clone(row.equipmentTypeName);
        this.key.equipmentTypeCd = this.$_.clone(row.equipmentTypeCd);
        this.key.plantCd = this.$_.clone(row.plantCd);
      }
      this.updateMode = true;
      this.updateMode2 = false;
      // 설비유형별 점검항목 조회
      this.$http.url = this.$format(this.itemDetailUrl, this.key.equipmentTypeCd, this.key.plantCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.insItemgrid.data = this.$_.clone(_result.data);
      },
      () => {
      });
    },
    addrow() {
      if (!this.key.equipmentTypeCd) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '설비유형을 선택하세요.', // 설비유형을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      // 초기에는 userId에 tempId를 부여
      this.insItemgrid.data.splice(0, 0, {
        equipmentTypeCd: this.key.equipmentTypeCd,
        plantCd: this.key.plantCd,
        equipmentCheckTypeCd: null,
        checkItemNo: uuidv4(),
        checkMethodCd: null,
        decisionBasis: '',
        checkCaution: '',
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        checkItemNm: '',
        click: '클릭',
        editFlag: 'C',
      })
      this.tempInsGridDetail = this.insItemgrid.data[0];
    },
    remove() {
      let selectData = this.$refs['itemTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.insItemgrid.data = this.$_.reject(this.insItemgrid.data, { checkItemNo: item.checkItemNo })
            })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.rowClick(null);
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    saveClass() {
      let checkItem = ['equipmentCheckTypeCd', 'checkItemNm']
      let isConti = true;
      this.$_.forEach(this.insItemgrid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [점검종류, 점검항목 내용]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        // let saveData = this.insItemgrid.data.filter( x => {
        //   return x.editFlag == 'C' || x.editFlag == 'U'
        // });
        let saveData = this.insItemgrid.data;
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.insertUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('ALERT', {
                  title: '안내 ', /* 안내 */
                  message: '저장되었습니다.', /* 저장되었습니다. */
                  type: 'success', // success / info / warning / error
                });
                this.rowClick(null);
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    innerBtnClicked(col, props) {
      this.insItemgrid.data.splice(props.rowIndex+1, 0, {
        equipmentTypeCd: props.row.equipmentTypeCd,
        plantCd: props.row.plantCd,
        equipmentCheckTypeCd: props.row.equipmentCheckTypeCd,
        checkItemNo: props.row.checkItemNo,
        checkItemNm: props.row.checkItemNm,
        checkMethodCd: null,
        decisionBasis: '',
        checkCaution: '',
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
  }
};
</script>
